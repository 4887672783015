import React, { Component } from 'react';
import { Jumbotron, Row, Col } from 'react-bootstrap';
import styled, {ThemeProvider} from 'styled-components'
import theme from "../../Config/Theme";

const Div = styled.div `

    .score-cards-big{
        background-color: ${(props) => props.theme.colors.dark_blue};
        color: white;
        border: 1px solid white;
        border-radius: 0.5rem;
        padding: 1rem;
        margin: 1rem;
    }

    .score-cards-sml{
        background-color: white;
        color: black;
        border: 1px solid white;
        border-radius: 0.25rem;
        padding: .25rem;
        margin: 0.5rem;
    }
`

class FullStatsCompany extends Component {

    render() {
        return (
            <Jumbotron className='text-dark'>
                <ThemeProvider theme={theme}>
                <Div>
                    <h3 className='display-4'>Stats by Company</h3>
                    {
                                        this.props.data.map((client) => {
                                            let consultingScoreDiv = 0;
                                            let talentScoreDiv = 0;

                                            const consultingScore = +client.formTalentedConsultants + +client.formConsultantUnderstands + +client.formTimely + +client.formSolutionAttitude + +client.formFairPrice + +client.formStakeholders + +client.formCommunications + +client.formProjects + +client.formOwnership + +client.formLearn + +client.formExpectations;

                                            if (client.formTalentedConsultants !== ""){ consultingScoreDiv++ };
                                            if (client.formConsultantUnderstands !== ""){ consultingScoreDiv++ };
                                            if (client.formTimely !== ""){ consultingScoreDiv++ };
                                            if (client.formSolutionAttitude !== ""){ consultingScoreDiv++ };
                                            if (client.formFairPrice !== ""){ consultingScoreDiv++ };
                                            if (client.formStakeholders !== ""){ consultingScoreDiv++ };
                                            if (client.formCommunications !== ""){ consultingScoreDiv++ };
                                            if (client.formProjects !== ""){ consultingScoreDiv++ };
                                            if (client.formOwnership !== ""){ consultingScoreDiv++ };
                                            if (client.formLearn !== ""){ consultingScoreDiv++ };
                                            if (client.formExpectations !== "") { consultingScoreDiv++ };
                                            
                                            const talentScore = +client.formCandidates + +client.formEfficiency + +client.formTalentStakeholders + +client.formTalentCommunications + +client.formTalentMethod + +client.formTalentPriorities + +client.formTalentExpectations;

                                            if (client.formCandidates !== ""){ talentScoreDiv++ };
                                            if (client.formEfficiency !== ""){ talentScoreDiv++ };
                                            if (client.formTalentStakeholders !== ""){ talentScoreDiv++ };
                                            if (client.formTalentCommunications !== ""){ talentScoreDiv++ };
                                            if (client.formTalentMethod !== ""){ talentScoreDiv++ };
                                            if (client.formTalentPriorities !== ""){ talentScoreDiv++ };
                                            if (client.formTalentExpectations !== ""){ talentScoreDiv++ };
                                            
                                            return (
                                                <div key={client.id} className='score-cards-big lead'>
                                                    <Row>
                                                        <Col>
                                                            <Row>
                                                                <Col>
                                                                    <h3 className="display-4">{client.orgName}</h3>
                                                                    <p>Respondent: {client.name}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col>
                                                            <Row>
                                                                <Col className='text-center score-cards-sml'>
                                                                    <p className='font-weight-bold'>Consulting Score:</p>
                                                                    <h4 className='display-1'>{consultingScore > 0 ? (consultingScore / consultingScoreDiv).toFixed(2) : "--"}</h4>
                                                                </Col>
                                                                <Col className='text-center score-cards-sml'>
                                                                    <p className='font-weight-bold'>Talent Score:</p>
                                                                    <h4 className='display-1'>{talentScore > 0 ? (talentScore / talentScoreDiv).toFixed(2) : "--"}</h4>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    
                                                    {(client.formWhichServices === "consulting" || client.formWhichServices === "all") ? 
                                                        <div>
                                                            <hr />
                                                            <p className='font-weight-bold'>Consulting Scores</p>
                                                    <Row>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Talented</p>
                                                            <p>Consultants</p>
                                                            <h4>{client.formTalentedConsultants > 0 ? client.formTalentedConsultants : "--"}</h4>
                                                        </Col>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Consultant</p>
                                                            <p>Understands</p>
                                                            <h4>{client.formConsultantUnderstands > 0 ? client.formConsultantUnderstands : "--"}</h4>
                                                        </Col>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Timely</p>
                                                            <p>Delivery</p>
                                                            <h4>{client.formTimely > 0 ? client.formTimely : "--"}</h4>
                                                        </Col>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Solution</p>
                                                            <p>Oriented</p>
                                                            <h4>{client.formSolutionAttitude > 0 ? client.formSolutionAttitude : "--"}</h4>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Fair</p>
                                                            <p>Price</p>
                                                            <h4>{client.formFairPrice > 0 ? client.formFairPrice : "--"}</h4>
                                                        </Col>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Stakeholder</p>
                                                            <p>Cooperation</p>
                                                            <h4>{client.formStakeholders > 0 ? client.formStakeholders : "--"}</h4>
                                                        </Col>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Timely</p>
                                                            <p>Communication</p>
                                                            <h4>{client.formCommunications > 0 ? client.formCommunications : "--"}</h4>
                                                        </Col>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Project</p>
                                                            <p>Customization</p>
                                                            <h4>{client.formProjects > 0 ? client.formProjects : "--"}</h4>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Ownership</p>
                                                            <p>Opportunities</p>
                                                            <h4>{client.formOwnership > 0 ? client.formOwnership : "--"}</h4>
                                                        </Col>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Learns</p>
                                                            <p>Culture</p>
                                                            <h4>{client.formLearn > 0 ? client.formLearn : "--"}</h4>
                                                        </Col>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Overall</p>
                                                            <p>Value</p>
                                                            <h4>{client.formExpectations > 0 ? client.formExpectations : "--"}</h4>
                                                        </Col>
                                                        <Col className="text-center score-cards-sml">
                                                            <p>Would</p>
                                                            <p>Refer Us?</p>
                                                            <h4>{client.formConsultingRefer}</h4>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className='score-cards-sml'>
                                                            <p className='font-weight-bold'>Where should we improve:</p>
                                                            <p>{client.formConsultingMore}</p>
                                                            <hr />
                                                            <p className='font-weight-bold'>Services we should add:</p>
                                                            <p>{client.formConsultingAreas}</p>
                                                            <hr />
                                                            <p className='font-weight-bold'>Reasons PPR was selected to be your expert partner:</p>
                                                            <p>{client.formConsultingReasons}</p>
                                                            <hr />
                                                            <p className='font-weight-bold'>Quote:</p>
                                                            <p>{client.formConsultingQuote}</p>
                                                        </Col>
                                                    </Row>
                                                        </div>
                                                     : null
                                                    }

                                                    {(client.formWhichServices === "talent" || client.formWhichServices === "all") ?
                                                        <div>
                                                            <hr/>
                                                            <p className='font-weight-bold'>TA Scores</p>
                                                            <Row>
                                                                <Col className="text-center score-cards-sml">
                                                                    <p>Talented</p>
                                                                    <p>Candidates</p>
                                                                    <h4>{client.formCandidates > 0 ? client.formCandidates : "--"}</h4>
                                                                </Col>
                                                                <Col className="text-center score-cards-sml">
                                                                    <p>Quality &</p>
                                                                    <p>Efficiency</p>
                                                                    <h4>{client.formEfficiency > 0 ? client.formEfficiency : "--"}</h4>
                                                                </Col>
                                                                <Col className="text-center score-cards-sml">
                                                                    <p>Stakeholder</p>
                                                                    <p>Cooperation</p>
                                                                    <h4>{client.formTalentStakeholders > 0 ? client.formTalentStakeholders : "--"}</h4>
                                                                </Col>
                                                                <Col className="text-center score-cards-sml">
                                                                    <p>Timely</p>
                                                                    <p>Communication</p>
                                                                    <h4>{client.formTalentCommunications > 0 ? client.formTalentCommunications : "--"}</h4>
                                                                </Col>     
                                                            </Row>
                                                            <Row>
                                                                <Col className="text-center score-cards-sml">
                                                                    <p>Unique</p>
                                                                    <p>Methodologies</p>
                                                                    <h4>{client.formTalentMethod > 0 ? client.formTalentMethod : "--"}</h4>
                                                                </Col>
                                                                <Col className="text-center score-cards-sml">
                                                                    <p>Learn</p>
                                                                    <p>Culture</p>
                                                                    <h4>{client.formTalentPriorities > 0 ? client.formTalentPriorities : "--"}</h4>
                                                                </Col>
                                                                <Col className="text-center score-cards-sml">
                                                                    <p>Overall Value</p>
                                                                    <p>Value</p>
                                                                    <h4>{client.formTalentExpectations > 0 ? client.formTalentExpectations : "--"}</h4>
                                                                </Col>
                                                                <Col className="text-center score-cards-sml">
                                                                    <p>Would</p>
                                                                    <p>Refer Us</p>
                                                                    <h4>{client.formTalentRefer}</h4>
                                                                </Col>     
                                                            </Row>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <p className='font-weight-bold'>Position we placed:</p>
                                                                    <p>{client.formPositions}</p>
                                                                    <hr />
                                                                    <p className='font-weight-bold'>Where should we improve:</p>
                                                                    <p>{client.formTalentApprove}</p>
                                                                    <hr />
                                                                    <p className='font-weight-bold'>Reasons PPR was selected to be your expert partner:</p>
                                                                    <p>{client.formTalentReasons}</p>
                                                                    <hr />
                                                                    <p className='font-weight-bold'>Quote:</p>
                                                                    <p>{client.formTalentQuote}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    
                                                    
                                                    {client.formCandaceA_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Candace Anderson</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formCandaceA_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formCandaceA_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formDorothyB_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Dorothy Buffington</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formDorothyB_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formDorothyB_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formDeniseMF_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Denise Maloney-Flores</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formDeniseMF_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formDeniseMF_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formHaleyM_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Haley McCartney</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formHaleyM_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formHaleyM_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formGabrielaN_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Gabriela Norton</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formGabrielaN_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formGabrielaN_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formHayleyT_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Hayley Tibbetts</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formHayleyT_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formHayleyT_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formJazminK_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Jazmin Kelly</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formJazminK_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formJazminK_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formKacyeB_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Kacye Benson</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formKacyeB_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formKacyeB_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formKathyB_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Kathy Bennett</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formKathyB_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formKathyB_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    
                                                    
                                                    {client.formLaurenL_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Lauren Lloyd</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formLaurenL_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formLaurenL_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formLukeB_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Luke Benson</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formLukeB_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formLukeB_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                    {client.formMercedesC_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Mercedes Contreras</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formMercedesC_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formMercedesC_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                    
                                                                                                        
                                                    {client.formTracyM_Rating > 0 ?
                                                        <div>
                                                            <hr/>
                                                            <Row>
                                                                <Col className='score-cards-sml'>
                                                                    <Row>
                                                                        <Col className='text-center'>
                                                                            <h4>Tracy Miller</h4>
                                                                            <p>Rating:</p>
                                                                            <div className='score-cards-big'>
                                                                                <h4 className='display-1'>{client.formTracyM_Rating}</h4>
                                                                            </div>
                                                                            
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{client.formTracyM_Comments}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                </div>
                                            )
                                        }                                        
                                    )
                                    }
                    </Div>
                    </ThemeProvider>
            </Jumbotron>
        )
    }
}

export default FullStatsCompany